class SignUp {
  constructor() {
    this.storageKey = 'my.sign_up_client';
    this.clientInfo = {
      fifth_element: '',
      company_name: '',
      ean: '',
      vat_id: '',
      line_1: '',  //address
      country_alpha2: '',
      city: '',
      name: '',
      email: '',
      phone_number: '',
      postal_code: '',
      password: '',
      contract_id: '',
      contract_name: '',
      active_contract_section: '',
    };
  }

  getClientInfo() {
    if (localStorage && !!localStorage.getItem(this.storageKey)) {
      const storageObj = JSON.parse(localStorage.getItem(this.storageKey));
      this.clientInfo = { ...this.clientInfo, ...storageObj };
    }
    return this.clientInfo;
  }

  updateClientInfo(clientUpdatedInfo) {
    if (localStorage) {
      const { company_name, ean, vat_id, line_1, country_alpha2, city, name, email, phone_number, password, postal_code, contract_id, fifth_element, contract_name, active_contract_section } = clientUpdatedInfo;
      const updatedClientObj = {
        ...this.clientInfo,
        ...{ company_name, ean, vat_id, line_1, country_alpha2, city, name, email, phone_number, password, postal_code, contract_id, fifth_element, contract_name, active_contract_section  },
      };
      let clientInfoString = JSON.stringify(updatedClientObj);
      localStorage.setItem(this.storageKey, clientInfoString);
    }
  }

  getI18nAttrNames() {
    return {
      companyI18n: I18n.t('generic.company'),
      eanI18n: I18n.t('signup.new_form.register.ean.placeholder'),
      vat_idI18n: I18n.t('signup.new_form.register.vat_id.placeholder'),
      line_1I18n: I18n.t('signup.new_form.register.line_1.placeholder'),
      postal_codeI18n: I18n.t('signup.new_form.register.postal_code.placeholder'),
      countryI18n: I18n.t('apps.lease.goto.types.country'),
      cityI18n: I18n.t('signup.new_form.register.city.placeholder'),
      nameI18n: I18n.t('signup.new_form.register.name.placeholder'),
      emailI18n:I18n.t('signup.new_form.register.email.placeholder'),
      phoneI18n: I18n.t('signup.new_form.register.phone_number.placeholder'),
      passwordI18n: I18n.t('signup.new_form.register.password.placeholder'),
    };
  }

  setCountryPhone() {}
}

export default new SignUp();
